export default function updateInfo(values, user, promoCode) {
  const data = {
    birth: new Date(values.year, values.month, values.day),
  };

  const fields = [
    "city",
    "commune",
    "firstName",
    "gender",
    "lastName",
    "middleName",
    "region",
    "rut",
    "surName",
  ];

  fields.forEach((key) => {
    data[key] = values[key];
  });

  return {
    ...user,
    info: { ...user.info, ...data },
    referralCode: promoCode.referralCode,
    signUpCode: promoCode.signUpCode,
    integrationPayload: {
      provider: promoCode.provider,
      token: promoCode.token,
    },
  };
}
