import { useApolloClient } from "@apollo/client";
import decodeToken from "contexts/auth/decodeToken";
import { useRouter } from "next/navigation";
import { FORGOT_PASSWORD, POST_SIGNIN } from "queries/session";
import { useState } from "react";
import { getItem, removeItem } from "utils";

function useSignIn() {
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const next = getItem("_bup.next");
  const { push } = useRouter();

  const onSubmit = async (values, { setErrors }) => {
    setLoading(true);
    if (values.view === "login") {
      try {
        const { data } = await client.mutate({
          mutation: POST_SIGNIN,
          variables: {
            email: values.email,
            password: values.password,
            app: "network",
          },
        });
        const { accessToken, refreshToken } = data.signin;
        decodeToken({ accessToken, refreshToken });
        push(next || "/app/overview");
        removeItem("_bup.next");
      } catch (error) {
        const errors = {
          "Password is not valid": "password",
          "User not found": "email",
        };
        const type = errors[error.graphQLErrors?.[0].extensions?.message];
        setErrors({ [type]: true });
        setLoading(false);
      }
    } else {
      try {
        await client.query({
          query: FORGOT_PASSWORD,
          variables: { email: values.email },
        });
        setIsSent(true);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  return {
    loading,
    onSubmit,
    isSent,
  };
}

export default useSignIn;
