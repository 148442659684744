import { LOGIN } from "consts";

const defaultValues = LOGIN.default;

function signUp(values) {
  const phone = `56${values.phone}`;
  const data = null;

  const user = {
    email: values.email,
    password: values.password,
    info: {
      rut: defaultValues.rut,
      birth: new Date(values.year, values.month, values.day),
      gender: defaultValues.gender,
      phone: phone,
      country: "Chile",
      region: defaultValues.region,
      city: defaultValues.city,
      commune: defaultValues.commune,
      firstName: defaultValues.firstName,
      middleName: defaultValues.middleName,
      lastName: defaultValues.lastName,
      surName: defaultValues.surName,
    },
    interests: [],
  };

  return {
    user,
    values: {
      ...values,
      ...user.info,
      email: values.email,
      password: values.password,
      phone: values.phone,
      interests: user.interests,
      day: values.day,
      month: values.month,
      year: values.year,
      view: "details",
    },
    errors: {
      phone: data !== null,
    },
  };
}

export default signUp;
