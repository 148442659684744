export default function errorInfo(values) {
  return [
    "birth",
    "commune",
    "firstName",
    "gender",
    "lastName",
    "region",
    "rut",
    "surName",
  ].some((x) => !values[x]);
}
