"use client";

import { Box, Link } from "components";
import { useConfig } from "contexts";
import helper from "utils/helper";
import { getById, openUrl } from "utils";

export default function About() {
  const { company, contact, documents } = useConfig();
  return (
    <Box className="BupLogin-links" component="ul">
      <Box component="li">
        <Link onClick={() => openUrl(company?.websiteUrl, 0, "_self")}>
          {company?.alias} &copy; {new Date().getFullYear()}
        </Link>
      </Box>
      <Box className="min-sm" component="li">
        <Link onClick={() => openUrl(documents?.standards)}>
          Normas comunitarias
        </Link>
      </Box>
      <Box component="li">
        <Link href={helper.getWhatsappUrl(contact?.phone)}>Ayuda</Link>
      </Box>
      <Box component="li">
        <Link onClick={() => getById("AS-reelVideo").click()}>
          Sobre {company?.alias}
        </Link>
      </Box>
    </Box>
  );
}
