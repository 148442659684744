import checkInfo from "./checkInfo";
import errorInfo from "./errorInfo";
import formatData from "./formatData";
import updateInfo from "./updateInfo";
import validate from "./validate";

const helper = {
  checkInfo,
  errorInfo,
  formatData,
  updateInfo,
  validate,
};

export default helper;
