import { gql } from "@apollo/client";

const POST_SIGNUP = gql`
  mutation Signup(
    $email: String!
    $info: SignUpProfileInput!
    $integrationPayload: SignUpIntegrationPayload
    $interests: [ID!]
    $password: String!
    $referralCode: String
    $signUpCode: String
    $source: String
  ) {
    signup(
      email: $email
      info: $info
      integrationPayload: $integrationPayload
      interests: $interests
      password: $password
      referralCode: $referralCode
      signUpCode: $signUpCode
      source: $source
    ) {
      tokens {
        accessToken
        refreshToken
      }
      user {
        _id
        email
      }
    }
  }
`;

export default POST_SIGNUP;
