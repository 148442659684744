import str from "utils/string";

export default function validate(values) {
  const errors = {};

  if (values.phone !== "") {
    const phone = values.phone.toString();
    if (phone.length === 9) values.phone = JSON.parse(phone.slice(0, 9));
    else errors.phone = true;
  }

  if (values.email !== "") {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    values.email = values.email?.toLowerCase();
    if (!regex.test(values.email)) errors.email = true;
  }

  if (values.password !== "") {
    if (values.password.length < 6) errors.password = true;
  }

  if (values.rut) {
    const rut = values.rut
        ?.toString()
        .replace(/[^0-9kK]/g, "")
        .slice(0, 9),
      a = rut.slice(0, -1),
      b = rut.slice(-1).toUpperCase(),
      size = a.length,
      aux = [];

    for (let i = 1; i <= size; i++) {
      aux.push(a[size - i]);
      if (i > 0 && i !== size && i % 3 === 0) aux.push(".");
    }

    let s = 0,
      m = 2;
    for (let i = 1; i <= size; i++) {
      let k = m * a.charAt(size - i);
      s += k;
      m = m < 7 ? m + 1 : 2;
    }

    const c = 11 - (s % 11),
      d = b == "K" ? 10 : b == 0 ? 11 : b;
    let error = size < 7 || size > 9 || c != d;

    if (!error) {
      values.rut = `${aux.reverse().join("")}${a.length > 2 ? "-" : ""}${b}`;
    } else {
      errors.rut = true;
      values.rut = a + b;
    }
  }

  ["firstName", "middleName", "lastName", "surName"].forEach((x) => {
    if (values[x]) values[x] = str.capitalize(values[x], true).trim();
  });

  return errors;
}
